import React from 'react'

import Layout from '../components/layout'

const NotFoundPage = ({location}) => (
	<Layout location={location}>
		<div>
			<h1>404 - Seite konnte nicht gefunden werden</h1>
			<p>
				Diese Seite existiert nicht, oder nicht mehr. Bitte beuchen Sie unsere
				anderen Seiten oder kontaktieren Sie den Administrator
			</p>
		</div>
	</Layout>
)

export default NotFoundPage
